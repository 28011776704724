<script setup lang="ts">
  import { useUserStore } from '@/stores/user'
  import { useProjectBackupStore } from '@/stores/project-backups'
  import fetchInterceptor from '@/interceptors/fetch'
  import { IntercomDes } from '@/helpers/intercomDes'
  import { MixpanelDes } from '@/helpers/mixpanelDes'
  import BugSnagDes from '@/helpers/bugSnagDes'
  import Hotjar from '@hotjar/browser'

  const user = useUserStore()
  if (user.user && !user.isClientAccount()) {
    user.getExtraSettings()
    user.getPublicConfig()
  }

  const backups = useProjectBackupStore()
  backups.openIndexedDb()

  fetchInterceptor(window)

  IntercomDes.setupIntercom(IntercomDes.skipIntercom)
  MixpanelDes.setupMixpanel(IntercomDes.skipIntercom)
  BugSnagDes.instance.setBugSnag(user.user)

  //use Hotjar only on APP
  if (window.location.hostname === 'app.designrr.io' && import.meta.env.VITE_HOTJAR_SITE_ID) {
    Hotjar.init(import.meta.env.VITE_HOTJAR_SITE_ID, 6)
  }
</script>

<template>
  <RouterView :key="$route.fullPath" />
</template>
