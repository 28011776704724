<script setup lang="ts">
  import { ref, watch } from 'vue'

  const props = defineProps<{
    open?: boolean
    bottom?: boolean
    right?: boolean
    left?: boolean
    scroll?: boolean
  }>()

  const element = ref<HTMLElement | null>(null)
  const flip = ref<boolean>(false)

  watch(
    () => props.open,
    () => {
      flip.value =
        !!element.value?.parentElement &&
        element.value.parentElement.getBoundingClientRect().bottom + 250 > window.innerHeight
    },
  )
</script>

<template>
  <Transition enter-from-class="scale-y-0 pointer-events-none" leave-to-class="scale-y-0 pointer-events-none">
    <div
      v-show="open"
      ref="element"
      class="absolute flex flex-col bg-white min-w-fit w-40 border border-white-gray p-2 gap-2 z-50 shadow-lg transition-transform origin-top rounded-lg"
      :class="{
        'top-0 right-full mx-3': !bottom && !right && !flip,
        'top-full right-0 mt-1': bottom && !right && !flip,
        'top-full left-0 mt-1': bottom && right && !flip,
        'top-0 left-full mx-3': !bottom && right && !flip,
        'bottom-0 right-full mx-3': !bottom && !right && flip,
        'bottom-full right-0 mt-1': bottom && !right && flip,
        'bottom-full left-0 mt-1': bottom && right && flip,
        'bottom-0 left-full mx-3': !bottom && right && flip,
        'origin-bottom': flip,
        'overflow-y-scroll max-h-72': scroll,
      }"
    >
      <slot></slot>
    </div>
  </Transition>
</template>
