import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useTimeoutFn, useStorage } from '@vueuse/core'
import type { TUserSortOptions } from '@/types/projects'
import type { TCombinedSearchResult } from '@/types/search'
import { useUserStore } from '@/stores/user'
import { http } from '@/helpers/http'
import type {
  TAudiobookCreateRes,
  TAudiobookPreview,
  TProjectAudiobook
} from '@/types/audiobooks'
import { useRoute } from 'vue-router'


export const useAudiobooksStore = defineStore('audiobooks', () => {
  const audiobooks = ref<TProjectAudiobook[]>([])
  const sortOptions = ref<TUserSortOptions>({})
  const user = useUserStore()
  const route = useRoute()
  const visible = ref<boolean>(false)

  async function fetchAudiobooks (query?: string) {
    sortOptions.value = useStorage('sortOptions', {} as TUserSortOptions).value
    const data: TProjectAudiobook[] | null = await http.post<TProjectAudiobook[] | null>('/tts/projects', { query: query ? query : '' })
    if (!data) {
      throw new Error(`Failed to fetch audiobooks`)
    }
    audiobooks.value = data;

    
    sort(sortOptions.value[user.user.id]?.projects)
  }

  async function checkAudiobooks(query?: string) {

    if (audiobooks.value.some(audiobook => audiobook.status !== 2)) {
      if (route.name === 'projects', visible.value === true) {      
        await fetchAudiobooks(query)      
      }      
    }    
    if (route.name === 'projects') {
      useTimeoutFn(() => {
        checkAudiobooks(query)
      }, 10e3)    
    }
  }

  async function deleteAudiobook (project: TProjectAudiobook) {
    const index = audiobooks.value.findIndex((item) => {
      return project.id === item.id
    })
    if (index >= 0) {
      audiobooks.value.splice(index, 1)
      const res = await fetch(`/tts/delete_audiobook_version`, {
        method: 'POST',
        body: JSON.stringify({
          book_id: project.id
        })
      })
      if (!res.ok) {
        throw new Error(`Failed to delete audiobook`)
      }
    }
  }

  async function renameAudiobook (book_id: number, bookName: string) {
    const res = await fetch('/tts/rename_audiobook', {
      method: 'POST',
      body: JSON.stringify({
        book_id,
        bookName
      })
    })
    if (!res.ok) {
      throw new Error(`Failed to rename project`)
    }
    const data = await res.json()
    return data
  }

  async function getAudiobookPreviewUrl (audiobook: TProjectAudiobook): Promise<string>
  async function getAudiobookPreviewUrl(id: number, token: string): Promise<string>
  async function getAudiobookPreviewUrl(arg1: TProjectAudiobook | number, arg2?: string): Promise<string> {
    const firstArgNotNumber = typeof arg1 !== 'number'
    const audiobookId = firstArgNotNumber && arg1.id ? arg1.id : arg1
    const token = firstArgNotNumber  && arg1.token ? arg1.token : arg2

    const res = await fetch(`/tts/preview/create_entry_token/${audiobookId}`, {
      method: 'POST',
    })
    if (!res.ok) {
      throw new Error(`Failed to rename project`)
    }

    const data = await res.json()
    const tokenToUse = token ? token : data.token

    return `/tts/preview/${tokenToUse}/${data.id}/1/1/1`
  }

  function sort (sortBy: string) {
    switch (sortBy) {
      case 'az':
        audiobooks.value.sort((a, b) => a.name.localeCompare(b.name))
        break
      case 'za':
        audiobooks.value.sort((a, b) => b.name.localeCompare(a.name))
        break
      case 'oldest':
        audiobooks.value.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
        break
      case 'newest':
        audiobooks.value.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        break
    }
  }

  async function preview (projectId: number): Promise<TAudiobookPreview | null> {
    return await http.post<TAudiobookPreview>('/tts/preview/create_entry_token/' + projectId)
  }

  function isInProgress(project: TProjectAudiobook | TCombinedSearchResult): boolean {
    return project?.status === 1
  }

  async function createDraft(
    content: string,
    articleTitle: string = '',
    author: string = '',
  ): Promise<TAudiobookCreateRes> {
    if (!author) {
      const userStore = useUserStore()
      author = userStore.getUserFullName(userStore.user)
    }
    if (!articleTitle) {
      articleTitle = 'New Audiobook'
    }
    return await http.post('/tts/created_draft', {
      content,
      articleTitle,
      author,
    })
  }
  return {
    createDraft,
    audiobooks,
    visible,
    isInProgress,
    preview,
    fetchAudiobooks,
    checkAudiobooks,
    deleteAudiobook,
    renameAudiobook,
    getAudiobookPreviewUrl,
    sort,
  }
})
